import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root:{
    marginBottom:'20px'
  },
  header: {
    height: 72,
    backgroundColor: "#FFF",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    //padding: 16,
  },
  stack: {
    flex: 1,
    overflow: "hidden",
    overflowX: "auto",
    margin: "0 16px 0 0",
  },
  tokenHeader: {
    display: "flex",
    height: 72,
    width: "100%",
    backgroundColor: `#FFF`,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    padding: "0 20px",
  },
  reccurHeader: {
    display: "flex",
    height: 72,
    width: "100%",
    backgroundColor: `#FFF`,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
    padding: "0 20px",
  },
  table: {
    backgroundColor: "#FFF",
    borderRadius: 5,
    paddingBottom: '26px',
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  verDivider: {
    opacity: 0.3,
    border: "1px solid #1B2B41",
    margin: "0 16px",
    height: 32,
  },
  tab: {
    cursor: "pointer",
  },
  tabItem: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "27px !important",
    color: "#1B2B41",
    opacity: 0.3,
  },
  tabItemActive: {
    opacity: 0.7,
  },
  helpCard: {
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "10px",
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    backgroundColor: "#76808D",
    zIndex: 999,
    width: "100% !important",
    height: "100%",
    opacity: 0.8,
    textAlign: "center",
    cursor: "pointer",
    padding: "10px",
  },
}));